

::placeholder {
  font-family: "Poppins";
  font-weight: 400;
}

.btn {
  display: flex;
  gap: 0.5em;
  align-items: flex-start;
}

a {
  text-decoration: none;
  color: #fff;
}

i {
  color: #000;
}

input {
  font-family: "Poppins";
  font-weight: 400;
  border: none;
  outline: none;
  background: #fff;
  padding: 0.25em 0.75em 0.3em 0.75em;
}

/*p,
span a {
  font-size: 14px;
}
*/
/* CSS */
button{
  background-color: transparent;
  border: 1px solid rgb(209,213,219);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #fff;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1rem 3rem;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: .5s;
}

.link a{
  text-decoration: none;
  color: white;
  transition: .5s;
   background: linear-gradient(to right, crimson,pink,springgreen);
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  background-clip: text;
  -webkit-background-clip:text;
  transition: color .2s ease-in-out;
  color:rgba(255,255,255,255);
}

.link a:hover{
  text-decoration: none;
  color:rgba(0,0,0,0);
  transition: .5s;
}

@keyframes rainbow { 
  0%{background-position:left}
  50%{background-position:right}
  100%{background-position:left}
}


button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
button:focus { 
  background-color: rgba(255,255, 255, 0.4);
}
button:active {
background-color: rgba(0, 0, 0, 0.8); }
button:visited { } /* Maybe less so */

.menu{
  background-color: transparent;
  border: 0px solid rgb(209,213,219);

  border-radius: .5rem;
  box-sizing: border-box;
  color: #fff;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1rem 1.25rem;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: .5s;

}


.btn span {
  padding: 0.3em 0.4em;
  background: #fff;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 30%,
    100% 70%,
    80% 100%,
    30% 100%,
    0 100%,
    0% 30%
  );
  font-size: 12px;
}

.logo-container {
  position: fixed;
  width: 100%;
  height: 125px;
  padding: 0 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0 0 20px 0;
  z-index: 2;
}

.logo {
  width: 100px;
  margin: 0;
}

.logo-bar {
  display: flex;
  width: 100%;
  font-family: "Poppins";
  font-weight: 400;
  padding: 1rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, -0.5);
  backdrop-filter: blur(0px);
  transition: 0.5s;
  clip-path: polygon(0 0, 0 125px, 100% 125px, 100% 0);
  z-index: 1000;
}

.container:hover{
  backdrop-filter: blur(20px);
  transition: 0.5s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.whitespace {
  width: 100%;
  height: 135px;
}

.item {
  display: flex;
  border-top: 1px dashed #fff;
  padding: 1em;
  font-family: "Poppins";
  font-weight: 400;
}

.item-span{
  margin-left:1em;
}

.col-1 {
  flex: 2;
}

.col-2 {
  flex: 4;
}
.col-3{
  flex:1;
}

.buy,
.connect,
.legal,
.newsletter {
  flex: 1;
}

.discover {
  flex: 10;
}

.discover-a {
  flex: 8;
}

.link {
  position: relative;
  top: 40px;
  opacity: 0;
}

.discover .col-2 a {
  font-family: "Poppins";
  text-transform: uppercase;
  text-decoration: none;
  line-height: 2.325rem;
  font-size: 42px;
}

.buy .col-2 {
  display: flex;
  width: 100%;
  gap: 2em;
}

.storerocket-result-website-link a{
    display: inline-block !important;
    font-size: 12px !important;
    color: #fff !important;
    font-weight: 600 !important;
    background-color: #020202 !important;
    border-radius: 2px !important;
    padding: 3px 8px !important;
}

@media (max-width: 900px) {

  .container {
    width: 100%;
    clip-path: polygon(0 0, 0 125px, 100% 125px, 100% 0);
  }

  .container:hover{
     -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

 .col-3{
  flex:2;
 }
 
 .col-1 {
    flex: 2;
  }
  .col-2 .link a {
    font-size: 12px;
  }

  .discover, .item,{
    font-size: 12px !important;
  }

  .discover-p{
    font-size:12px !important;
  }

  #micro_svg{
    width: 60px !important;
  }

  .fa{
    font-size: 20px !important;
  }

  .logo-container {
  position: fixed;
  width: 100%;
  height: 125px;
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0 0 20px 0;
  z-index: 2;
}

.logo-bar {
  display: flex;
  width: 100%;
  font-family: "Poppins";
  font-weight: 400;
  padding: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}


}
